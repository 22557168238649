<template>
  <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
    <v-container fluid>

      <validation-observer ref="form" >
        <v-form @submit.prevent="submit" class="mb-16">
          <v-row class="mb-5" align="center" justify="start">

            <!-- Botões de navegação entre datas, SO PARA MOBILE -->
            <v-col v-if="method=='update'" cols="6" class="mb-4 text-right d-sm-none">
              <v-btn class="primary" fab @click="navigateDate(-1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="method=='update'" cols="6" class="mb-4 text-left d-sm-none">
              <v-btn fab class="primary" :disabled="isNextDayDisabled" @click="navigateDate(1)">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="1" class="mb-4 text-right d-none d-sm-flex">
              <v-btn icon @click="navigateDate(-1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" md="3" class="mt-2">
              <v-menu
                ref="menu"
                v-model="menuDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider v-slot="{ errors }" vid="Data" name="Data" rules="required">
                    <v-text-field
                      v-model="fields.date"
                      label="Selecione a Data *"
                      prepend-inner-icon="mdi-calendar"
                      dense
                      outlined
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="fields.date"
                  no-title
                  scrollable
                  :allowed-dates="(date) => date <= new Date(new Date() + 1).toISOString().substr(0, 10)"
                  @input="menuDate = false; "
                  @change="checkDateUser"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="1" class="mb-4 text-left d-none d-sm-flex">
              <v-btn icon :disabled="isNextDayDisabled" @click="navigateDate(1)">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" md="3"  class="text-right">
              <v-text-field
                class="mt-2"
                dense outlined
                label="Total de Minutos:"
                readonly
                :value="totalMinutos"
              />
            </v-col>

            <v-col cols="12" md="3" class="text-right">
              <v-text-field
                class="mt-2"
                dense outlined
                label="Total de Horas:"
                readonly
                :value="totalHoras"
              />
            </v-col>

          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <p v-if="$root.session.hasPermission(['super', 'diaries.approve'])">
                Diária de: {{ fields.user_name }}
              </p>
            </v-col>
          </v-row>

          <v-divider class="mb-4"></v-divider>

          <fieldset :disabled="loading">

            <v-simple-table class="mt-5 d-none d-lg-block">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Cliente</th>
                    <th>Projeto</th>
                    <th>Minutos</th>
                    <th>Descrição</th>
                    <th v-if="$root.session.hasPermission(['super', 'diaries.approve'])">Minutos Cliente</th>
                    <th v-if="$root.session.hasPermission(['super', 'diaries.approve'])">Aprovação</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(line, index) in fields.lines" :key="line.id">

                    <td>
                      <validation-provider v-slot="{ errors }" vid="Cliente" name="Cliente" rules="required">
                        <v-select
                          class="mt-6"
                          v-model="line.client_id"
                          :items="clients"
                          item-value="id"
                          item-text="name"
                          :error-messages="errors"
                          label="Cliente*"
                          dense
                          outlined
                          @change="filtrarProjetos(line.client_id, index)"
                          :disabled="!!line.approved"
                        ></v-select>
                      </validation-provider>
                    </td>

                    <td>
                      <validation-provider v-slot="{ errors }" vid="Projeto" name="Projeto" rules="required">
                        <v-select
                          class="mt-6"
                          v-model="line.project_id"
                          :items="line.projects"
                          item-value="id"
                          item-text="name"
                          label="Projeto*"
                          dense
                          outlined
                          @change="filtrarPacotes(line.client_id, index)"
                          :error-messages="errors"
                          :disabled="!!line.approved"
                        ></v-select>
                      </validation-provider>
                    </td>

                    <td>
                      <validation-provider v-slot="{ errors }" vid="Minutos" name="Minutos" rules="required">
                        <v-text-field
                          class="mt-6"
                          v-model="line.minutos"
                          label="Minutos*"
                          dense
                          outlined
                          type="number"
                          :error-messages="errors"
                          :disabled="!!line.approved"
                        ></v-text-field>
                      </validation-provider>
                    </td>

                    <td>
                      <validation-provider v-slot="{ errors }" vid="Descrição" name="Descrição" rules="required">
                        <TextEditorModal
                          v-model="line.job_description"
                          :disabled="!!line.approved"
                        />
                        <span v-if="errors.length" class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </td>

                    <td v-if="$root.session.hasPermission(['super', 'diaries.approve'])">
                        <validation-provider v-slot="{ errors }" vid="Minutos Cliente" name="Minutos Cliente">
                          <v-text-field
                            class="mt-6"
                            v-model="line.minutos_cliente"
                            label="Minutos Cliente*"
                            dense
                            outlined
                            type="number"
                            :error-messages="errors"
                            :disabled="!!line.approved"
                          ></v-text-field>
                        </validation-provider>
                      </td>

                    <td v-if="$root.session.hasPermission(['super', 'diaries.approve'])">
                      <validation-provider v-slot="{ errors }" vid="Aprovação da descrição" name="Aprovação da descrição">
                        <TextEditorModal
                          v-model="line.job_description_approved"
                          :disabled="!!line.approved"
                        />
                        <span v-if="errors.length" class="text-danger">{{ errors[0] }}</span>
                      </validation-provider>
                    </td>

                    <td v-if="$root.session.hasPermission(['super', 'diaries.approve'])">
                      <validation-provider v-slot="{ errors }" vid="Pacote" name="Pacote" rules="required">
                        <v-select
                          class="mt-6"
                          v-model="line.package_id"
                          :items="line.packages"
                          item-value="id"
                          :item-text="item => line.approved ? item.name : `${item.name} --  ${item.hours_left}h`"
                          label="Pacote*"
                          dense
                          outlined
                          :error-messages="errors"
                          :disabled="!!line.approved"
                          @change="validaPacote(line)"
                        ></v-select>
                      </validation-provider>
                    </td>

                    <td v-if="$root.session.hasPermission(['super', 'diaries.approve'])">
                      <validation-provider v-slot="{ errors }" vid="Aprovação" name="Aprovação">
                        <v-btn
                          color="success"
                          icon
                          :disabled="!!line.approved"
                          :error-messages="errors"
                          @click="approveLine(index)"
                        >
                          <v-icon>mdi-sticker-check-outline</v-icon>
                        </v-btn>
                        <input
                          type="hidden"
                          v-model="line.approved"
                        />
                      </validation-provider>
                    </td>

                    <td>
                      <DropImage :line_id="String(line.id)" />
                    </td>

                    <td>
                      <v-btn
                        color="red"
                        icon
                        @click="removeLine(index, line)"
                        :disabled="!!line.approved"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <v-data-iterator :items="fields.lines" item-key="id" :items-per-page="-1" hide-default-footer class="d-block d-lg-none">
              <template v-slot:default="{ items }">
                <v-row>
                  <v-col v-for="line in items" :key="line.id" cols="12" >
                    <v-card>
                      <v-list dense>
                        <v-list-item>
                          <v-list-item-content>Cliente:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <validation-provider v-slot="{ errors }" vid="Cliente" name="Cliente" rules="required">
                              <v-select
                                class="mt-6"
                                v-model="line.client_id"
                                :items="clients"
                                item-value="id"
                                item-text="name"
                                :error-messages="errors"
                                label="Cliente*"
                                dense
                                outlined
                                @change="filtrarProjetos(line.client_id, index)"
                                :disabled="!!line.approved"
                              ></v-select>
                            </validation-provider>
                          </v-list-item-content>
                        </v-list-item>


                        <v-list-item>
                          <v-list-item-content>Projeto:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <validation-provider v-slot="{ errors }" vid="Projeto" name="Projeto" rules="required">
                              <v-select
                                class="mt-6"
                                v-model="line.project_id"
                                :items="line.projects"
                                item-value="id"
                                item-text="name"
                                label="Projeto*"
                                dense
                                outlined
                                @change="filtrarPacotes(line.client_id, index)"
                                :error-messages="errors"
                                :disabled="!!line.approved"
                              ></v-select>
                            </validation-provider>
                          </v-list-item-content>
                        </v-list-item>


                        <v-list-item>
                          <v-list-item-content>Minutos:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <validation-provider v-slot="{ errors }" vid="Minutos" name="Minutos" rules="required">
                              <v-text-field
                                class="mt-6"
                                v-model="line.minutos"
                                label="Minutos*"
                                dense
                                outlined
                                type="number"
                                :error-messages="errors"
                                :disabled="!!line.approved"
                              ></v-text-field>
                            </validation-provider>
                          </v-list-item-content>
                        </v-list-item>


                        <v-list-item>
                          <v-list-item-content>Descrição:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <validation-provider v-slot="{ errors }" vid="Descrição" name="Descrição" rules="required">
                              <TextEditorModal
                                v-model="line.job_description"
                                :disabled="!!line.approved"
                              />
                              <span v-if="errors.length" class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </v-list-item-content>
                        </v-list-item>
                      

                        <v-list-item v-if="$root.session.hasPermission(['super', 'diaries.approve'])">
                          <v-list-item-content>Minutos Cliente:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <validation-provider v-slot="{ errors }" vid="Minutos Cliente" name="Minutos Cliente">
                              <v-text-field
                                class="mt-6"
                                v-model="line.minutos_cliente"
                                label="Minutos Cliente*"
                                dense
                                outlined
                                type="number"
                                :error-messages="errors"
                                :disabled="!!line.approved"
                              ></v-text-field>
                            </validation-provider>
                          </v-list-item-content>
                        </v-list-item>
                      
                        
                        <v-list-item v-if="$root.session.hasPermission(['super', 'diaries.approve'])">
                          <v-list-item-content>Aprovação da descrição:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <validation-provider v-slot="{ errors }" vid="Aprovação da descrição" name="Aprovação da descrição">
                              <TextEditorModal
                                v-model="line.job_description_approved"
                                :disabled="!!line.approved"
                              />
                              <span v-if="errors.length" class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                          </v-list-item-content>
                        </v-list-item>
                      
                        
                      <v-list-item v-if="$root.session.hasPermission(['super', 'diaries.approve'])">
                        <v-list-item-content>Pacote:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          <validation-provider v-slot="{ errors }" vid="Pacote" name="Pacote" rules="required">
                            <v-select
                              class="mt-6"
                              v-model="line.package_id"
                              :items="line.packages"
                              item-value="id"
                              :item-text="item => line.approved ? item.name : `${item.name} --  ${item.hours_left}h`"
                              label="Pacote*"
                              dense
                              outlined
                              :error-messages="errors"
                              :disabled="!!line.approved"
                              @change="validaPacote(line)"
                            ></v-select>
                          </validation-provider>
                        </v-list-item-content>
                      </v-list-item>
                      
                        
                      <v-list-item v-if="$root.session.hasPermission(['super', 'diaries.approve'])">
                        <v-list-item-content>Aprovação:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          <validation-provider v-slot="{ errors }" vid="Aprovação" name="Aprovação">
                            <v-btn
                              color="success"
                              icon
                              :disabled="!!line.approved"
                              :error-messages="errors"
                              @click="approveLine(index)"
                            >
                              <v-icon>mdi-sticker-check-outline</v-icon>
                            </v-btn>
                            <input
                              type="hidden"
                              v-model="line.approved"
                            />
                          </validation-provider>
                        </v-list-item-content>
                      </v-list-item>
                      
                        
                      <v-list-item>
                        <v-list-item-content>Imagem:</v-list-item-content>
                        <v-list-item-content class="align-end">
                          <v-row>
                            <v-col cols="6" md="auto">
                              <DropImage :line_id="String(line.id)" />
                            </v-col>
                            <v-col cols="6" md="auto">
                              <v-btn
                                color="red"
                                icon
                                @click="removeLine(index, line)"
                                :disabled="!!line.approved"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>

            <v-row class="mt-6" align="center" justify="center">
              <v-btn :disabled="fields.date == null" color="primary" @click="addLine">+</v-btn>
            </v-row>

            <v-divider class="mt-4"></v-divider>

            <v-row class="mt-6" align="center" justify="center">
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  large
                  @click="submit"
                >
                  Gravar Diária
                </v-btn>
              </v-col>
            </v-row>

          </fieldset>
        </v-form>
      </validation-observer>

      <DialogSuccess :opened="success" @on-ok="success = false; $emit('reload'); $router.push('/diaries');">
        Diária gravada com sucesso
      </DialogSuccess>

      <ErrorSnackbar v-model="showError" :message="errorMessage" />

    </v-container>
  </v-card>
</template>

<script>
import Diaries from "@/api/Diaries.js";
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Client from '@/api/Client.js';
import Project from "@/api/Project.js";
import dayjs from 'dayjs';
import Package from "../../api/Package";
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';
import DropImage from '@/components/diaries/images/DropImage.vue';
import TextEditorModal from '@/components/ui/Modals/TextEditorModal.vue';

export default {
  components: {
    DialogSuccess,
    ErrorSnackbar,
    DropImage,
    TextEditorModal
  },
  props: {
    diary: Object,
    method: String,
    disabled: Boolean
  },
  watch: {
    diary: {
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }

        let data = { ...val };

        this.fields = data;

        this.fields.user_name = val.user_name;
        this.fields.user_id = val.user_id;

        console.log("user_name", this.fields.user_name);

        this.fields.lines.forEach((line, index) => {
          if (line.client_id) {
            this.filtrarProjetos(line.client_id, index);
            this.filtrarPacotes(line.client_id, index);
          }

          if (line.approved === 1) {
            this.$set(this.fields.lines[index], "approved", true);
          } else {
            this.$set(this.fields.lines[index], "approved", false);
          }

          if (line.job_description_approved == null) {
            this.$set(this.fields.lines[index], "job_description_approved", line.job_description);
          }

          if (line.minutos_cliente == null) {
            this.$set(this.fields.lines[index], "minutos_cliente", line.minutos);
          }
        });
      }
    }
  },
  data() {
    return {
      fields: {
        id: null,
        date: null,
        lines: [],
        user_name: '',
        user_id: null
      },
      clients: [],
      packages: [],
      projects: [],
      deletedLines: [],
      menuDate: false,
      loading: false,
      currentLine: null,
      error: {
        title: '',
        message: ''
      },
      success: false,
      menu: false,
      showError: false,
      errorMessage: '',
      options: {
        page: 1,
        itemsPerPage: 10
      },
      filter: {
        name: null,
        display_name: null,
        user_id: null,
        deleted: false,
        diary_approved: null
      }
    };
  },
  mounted() {
    if (!this.$root.session.hasPermission(["super", "diaries.write"])) {
      this.$router.push('/admin');
    }
    this.fillBaseData();
  },
  methods: {
    addLine() {
      this.fields.lines.push({
        id: dayjs().unix(),
        client_id: null,
        project_id: null,
        minutos: null,
        job_description: null,
        minutos_cliente: null,
        job_description_approved: null,
        approved: false,
        package_id: null
      });
    },
    fillBaseData() {
      Client.list().then((clients) => {
        this.clients = clients.data;
      });
    },
    filtrarProjetos(client_id, index) {
      let params = {
        client_id: client_id
      };
      Project.filtered(params).then((projects) => {
        this.$set(this.fields.lines[index], "projects", projects.data);
      });
    },
    filtrarPacotes(client_id, index) {
      let params = {
        client_id: client_id
      };
      Package.filteredPackages(params).then((packagesResponse) => {
        const packages = packagesResponse.data;
        const filteredPackages = packages.filter(pkg => pkg.hours_left > 0 || pkg.id === this.fields.lines[index].package_id);
        this.$set(this.fields.lines[index], "packages", filteredPackages);
      });
    },


    removeLine(index, line) {
      if (line.id) {
        this.deletedLines.push(line.id);
      }
      this.fields.lines.splice(index, 1);
    },


    async approveLine(index) {
      await this.submit(false);

      const line = this.fields.lines[index];

      if (line.package_id === null) {
        this.errorMessage = "Selecione o pacote pf";
        this.showError = true;
        return;
      }

      const lineData = {
        line_id: line.id,
        client_id: line.client_id,
        package_id: line.package_id,
        hours_left: line.packages?.[0]?.hours_left,
        hours_spent: line.packages?.[0]?.hours_spent,
        minutos: line.minutos,
        minutos_cliente: line.minutos_cliente,
        job_description_approved: line.job_description_approved,
        project_id: line.project_id,
        comment_date: this.fields.date
      };

      this.loading = true;

      this.$set(this.fields.lines[index], "approved", true);

      await Diaries.approveLines(lineData, line.id);

      this.loading = false;

      let i = 0;
      this.fields.lines.forEach(element => {
        this.filtrarPacotes(element.client_id, i);
        i++;
      });
    },
    validaPacote(line) {
      let selectedPackage = null;

      for (let pack of line.packages) {
        if (pack.id === line.package_id) {
          selectedPackage = pack;
        }
      }

      const minutesLeft = selectedPackage.hours_left * 60;

      if (line.minutos_cliente > minutesLeft) {
        this.$set(line, 'package_id', null);
        this.errorMessage = 'Este pacote não tem horas necessárias para este registo.';
        this.showError = true;
      }
    },
    async checkDateUser() {
      console.log("MUDEI");
      const response = await Diaries.checkDate(this.fields.date);

      if (response.data === false) {
        this.fields.date = null;
        this.showError = true;
        this.errorMessage = "Já tens diária para este dia!";
      }
    },
    onSuccess() {
      if (this.fields.id != null) {
        this.success = false;
        return;
      }
      this.$router.push('/diaries');
    },
    async submit(redirect = true) {
      this.$refs.form.validate().then(async (result) => {
        if (!result) return;

        this.loading = true;

        const dataToBackEnd = {
          id: this.fields.id,
          date: this.fields.date,
          lines: this.fields.lines,
          deletedLines: this.deletedLines
        };

        await Diaries[this.method](dataToBackEnd, this.fields.id).then(() => {
          this.loading = false;
          if (redirect) {
            this.success = true;
          }
        }).catch(err => {
          this.loading = false;

          if (err.response.status == 422) {
            this.$refs.form.setErrors(err.response.data.errors);
            return;
          }

          this.error.title = this.$t('globals.error') + " " + err.response.status;
          this.error.message = err.response.data.message;
        });
      });
    },
    async navigateDate(days) {
      const newDate = dayjs(this.fields.date).add(days, 'day').format('YYYY-MM-DD');
      this.fields.date = newDate;
      await this.loadDiariesForDate(newDate);
    },

    async loadDiariesForDate(date) {
    try {
      console.log("Nova data:");
      console.log(date);

      // Fetch the diary header for the given date and user
      const response = await Diaries.findByDateAndUser({ user_id: this.fields.user_id, date: date });

      console.log("Resposta para a nova data");
      console.log(response);

      if (response.data) {
        this.fields = { ...response.data };
      } else {
        this.fields = {
          id: null,
          date: date,
          lines: [],
          user_name: this.fields.user_name,
          user_id: this.fields.user_id
        };
      }
    } catch (error) {
      console.error(error);
      this.showError = true;
      this.errorMessage = "Erro ao carregar diárias para a data selecionada.";
    }
    },
    viewDiaries(item) {
      this.$router.push('diaries/update/' + item.id);
    }
  },
  computed: {
    isNextDayDisabled() {
      const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
      return this.fields.date >= tomorrow;
    },
    totalMinutos() {
      return this.fields.lines.reduce((total, line) => total + (parseInt(line.minutos) || 0), 0);
    },
    totalHoras() {
      const totalMinutos = this.totalMinutos;
      return Math.floor(totalMinutos / 60) + 'h ' + (totalMinutos % 60) + 'm';
    }
  }
};
</script>